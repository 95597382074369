import React from "react";
var __jsx = React.createElement;
import Moment from 'react-moment';
var LocalizedTime = function LocalizedTime(props) {
  var children = props.children,
    format = props.format,
    parse = props.parse;
  return (
    // @ts-ignore
    __jsx(Moment, {
      format: format,
      testID: 'localizedTime',
      parse: parse
    }, children)
  );
};
export default LocalizedTime;