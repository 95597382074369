import React from "react";
var __jsx = React.createElement;
import { Body2 } from '~/styles/Theme';
var AnnouncementText = function AnnouncementText(props) {
  var content = props.content;
  return __jsx(Body2, null, __jsx("div", {
    dangerouslySetInnerHTML: {
      __html: content
    }
  }));
};
export default AnnouncementText;