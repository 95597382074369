import { Platform } from 'react-native';
import { runOnPlatform } from '~/common/Helpers';
export var DOP_DOP_BUSINESS_ID = 'n98ZUSbx05dEg5rHqEZhig';
export function shouldDisplayGallery(business) {
  return business.id === DOP_DOP_BUSINESS_ID;
}
function formatAddress(address, addressLine) {
  if (addressLine === '' || addressLine === null) {
    return '';
  }
  if (addressLine) {
    if (addressLine === address.postalCode && address.country === '') {
      return addressLine;
    }
    if (addressLine.substr(-1) !== ',' && addressLine !== address.country) {
      return addressLine + ', ';
    } else {
      return addressLine + ' ';
    }
  }
  return '';
}
export function buildAddress(address) {
  return formatAddress(address, address.streetAddress1) + formatAddress(address, address.streetAddress2) + formatAddress(address, address.city) + formatAddress(address, address.state) + formatAddress(address, address.postalCode) + formatAddress(address, address.country);
}

// Function to build the Google Maps link in the browser
var buildGoogleMapsLink = function buildGoogleMapsLink(name, address) {
  var encodedAddress = encodeURIComponent("".concat(name, ", ").concat(buildAddress(address)));
  return "https://www.google.com/maps/search/?api=1&query=".concat(encodedAddress);
};
function getMapScheme() {
  switch (Platform.OS) {
    case 'ios':
      return "maps:0,0?q=";
    case 'android':
      return "geo:0,0?q=";
    default:
      return '';
  }
}
function getMapQuery(latLng, label) {
  var scheme = getMapScheme();
  switch (Platform.OS) {
    case 'ios':
      return "".concat(scheme).concat(label, "@").concat(latLng);
    case 'android':
      return "".concat(scheme).concat(latLng, "(").concat(label, ")");
    default:
      return '';
  }
}

// @ts-ignore TS7030
export function buildMapAddress(branch) {
  return runOnPlatform({
    web: function web() {
      return buildGoogleMapsLink(branch.name, branch.address);
    },
    "native": function native() {
      if (branch.coordinates && branch.coordinates.latitude && branch.coordinates.longitude) {
        var latLng = "".concat(branch.coordinates.latitude, ",").concat(branch.coordinates.longitude);
        var label = encodeURIComponent(branch.name);
        return getMapQuery(latLng, label);
      }
      return undefined;
    }
  });
}
export function buildOpeningTimes(workWeek) {
  var times = workWeek.days.map(function (day) {
    return day.times.map(function (item) {
      var date = day.name;
      var startTime = item.start;
      var endTime = item.end;
      return {
        date: date,
        startTime: startTime,
        endTime: endTime
      };
    });
  });
  return times;
}
export function findLastNonCancelledPreviousAppointment(viewModel) {
  return viewModel.find(function (appointment) {
    return appointment.serviceItems.find(function (service) {
      return service.cancelled === false && service.fee !== null;
    }) !== undefined;
  });
}